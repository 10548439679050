.eco_calendar {
  padding-right: 10px;
}

body[dir="rtl"] .eco_calendar {
  padding-left: 10px;
  padding-right: 0;
}

.eco_calendar + div {
  padding-left: 10px;
}

body[dir="rtl"] .eco_calendar + div {
  padding-right: 10px;
  padding-left: 0;
}

.heigth {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.heigthNewTab {
  height: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

iframe .widget-economic-calendar {
  background: red;
}

.economic_calendar_filter ul {
  padding: 0;
  list-style: outside none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.economic_calendar_filter li {
  list-style: none;
  float: right;
  display: flex;
  padding-right: 20px;
  margin: 6px 0;
}

body[dir="rtl"] .economic_calendar_filter li {
  float: left;
  padding-left: 20px;
  padding-right: 0;
}

.economic_calendar_filter label {
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-bottom: 0;
}

.economic-calendar__importance {
  margin-right: 5px;
}

body[dir="rtl"] .economic-calendar__importance {
  margin-left: 5px;
  margin-right: 0;
}

.economic-calendar__importance,
.economic-calendar__table-importance span {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background: #fff;
}

.economic-calendar__importance.high,
.economic-calendar__table-importance.high span {
  background: #ff4f65;
}

.economic-calendar__importance.medium,
.economic-calendar__table-importance.medium span {
  background: #ffcc36;
}

.economic-calendar__importance.low,
.economic-calendar__table-importance.low span {
  background: #ccc;
}

.economic-calendar__importance.none,
.economic-calendar__table-importance.none span {
  background: #fff;
  border: 1px solid #ccc;
}

.economic_calendar_filter.eco_big_calendar {
  border: none;
  padding: 0 20px;
}

.eco_calendar iframe {
  border: none;
  height: 100%;
}
.eco_calendar .main_half {
  height: calc(100% - 39px);
}

.eco_ifrm {
  height: calc(100% - 50px);
}

@media (max-width: 1366px) {
  .widthcalendar,
  .top_losers_tables {
    width: 100%;
  }
  .widthcalendar {
    margin-bottom: 20px;
  }
  .eco_calendar + div {
    padding-left: 0;
  }

  body[dir="rtl"] .eco_calendar + div {
    padding-right: 0;
  }
  .eco_calendar {
    padding-right: 0;
  }

  body[dir="rtl"] .eco_calendar {
    padding-left: 0;
  }

  .eco_calendar .main_half {
    height: 420px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 992px) {
  .eco_calendar iframe,
  .heigthNewTab iframe {
    min-width: 910px;
  }

  .eco_ifrm,
  .heigthNewTab {
    overflow: auto;
  }
  .eco_calendar.heigth {
    width: 100%;
  }
  .eco_ifrm {
    height: calc(100% - 90px);
  }

  .eco_calendar .swipe {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 377px) {
  .eco_ifrm {
    height: calc(100% - 95px);
  }
}
