.review_btn {
  width: 100%;
  height: 45px;
  background-color: #94c93d;
}

.form_review {
  margin-top: 15px;
}

.form_review label {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: #9b9b9b;
  padding-bottom: 0;
}

.form_review input {
  width: 100%;
  height: 44px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-size: 14px;
  font-family: roboto;
}

.form_review textarea {
  width: 100%;
  height: 160px;
  resize: none;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  font-family: roboto;
}

.your_rate_block {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 0;
  box-sizing: border-box;
}

.your_rate {
  margin-right: 35px;
}

.your_rate + div {
  height: 38px;
}

.review_block p {
  color: black;
  font-weight: normal;
}
