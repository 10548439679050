.brokers_container.main_with_left + .right_box_block .right_box {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.analysis_cont {
  height: 100%;
  display: flex;
}

.brokers_container.main_with_left + .right_box_block .right_box .analysis {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.analysis {
  text-align: left;
  padding: 0 10px 10px;
  overflow: auto;
  max-height: 780px;
}

body[dir="rtl"] .analysis {
  text-align: right;
}

.right_box > .analysis_cont {
  padding: 20px 10px 10px;
}

.analysis .title_block {
  font-size: 16px;
  color: #000000;
  margin-bottom: 5px;
  line-height: normal;
  font-weight: normal;
  font-family: Montserrat;
}

.analysis .text_block {
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.right_box .analysis_blocks {
  padding-bottom: 20px;
}

.right_box .analysis_blocks:last-child {
  padding-bottom: 20px;
}

@media screen and (max-width: 992px) {
  .brokers_rating .main_with_left {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .brokers_rating .right_box_block {
    width: 100%;
  }
}
