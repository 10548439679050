.bg_wrap,
.txt_wrap {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

/*TESTAVORMAN HAMARA, HETO ES KJNJEQ*/
.news_right_part > div.news_content > div:first-of-type {
  overflow: hidden;
  max-height: 105px;
  margin-bottom: 80px;
  line-height: 1.57;
  font-weight: normal;
}

/*END*/

div.news_content {
  position: relative;
  height: 100%;
  text-align: left;
}

body[dir="rtl"] div.news_content {
  text-align: right;
}

div.news_content h2 {
  margin-bottom: 13px;
}

@media (max-width: 700px) {
  .news_wrap {
    flex-direction: column;
    padding-right: 20px;
  }

  body[dir="rtl"] .news_wrap {
    padding-left: 20px;
    padding-right: 0;
  }

  .news_right_part {
    width: 100%;
  }
}
