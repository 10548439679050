.promotions_right_block {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d9e3f0;
}

.promotions_right_block:last-child {
  border: none;
  padding-bottom: 0;
}
.promotions_right_block span {
  font-weight: 500;
  color: #000;
}
.top_promotions {
  min-height: 280px;
}

.promotions_right_block > div {
  font-size: 14px;
  font-weight: normal;
  color: #4a4a4a;
}

.promotions_right_block > div:hover {
  text-decoration: underline;
}
