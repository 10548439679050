.news_wrap {
    display: flex;
    height: 100%;
    padding-right: 30px;
    justify-content: flex-start;
    align-items: stretch;
}

body[dir='rtl'] .news_wrap {
    padding-right: 20px;
    padding-left: 30px;
}

.news_left_part {
    max-width: 500px;
    margin-right: 20px;
}

body[dir='rtl'] .news_left_part {
    margin-right: 0;
    margin-left: 20px;
}

.news_right_part {
    width: calc(100% - 520px);
}

.news_content {
    position: relative;
    height: 100%;
    text-align: left;
}

body[dir='rtl'] .news_content {
    text-align: right;
}

.news_title {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: normal;
    display: block;
    margin-bottom: 20px;
    color: #000;
}

.date_block {
    position: absolute;
    bottom: 0;
    left: 0;
}

body[dir='rtl'] .date_block {
    right: 0;
    left: auto;
}

.news_content > div:first-of-type {
    font-weight: normal;
    line-height: 1.57;
    overflow: hidden;
    max-height: 105px;
    margin-bottom: 80px;
}

@media (max-width: 767px) {
    .news_left_part {
        max-width: initial;
        margin-right: 0;
        margin-bottom: 20px;
    }

    body[dir='rtl'] .news_left_part {
        margin-left: 0;
    }

    .news_wrap {
        flex-direction: column;
        padding-right: 20px;
    }

    body[dir='rtl'] .news_wrap {
        padding-left: 20px;
    }

    .news_right_part {
        width: 100%;
    }
}