.button {
  background-color: #00bfff;
  border: none;
  font-weight: bold;
  padding: 12px 15px;
  color: #ffffff;
  cursor: pointer;
}

.reactStars {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 0;
  box-sizing: border-box;
}

.errorMsg {
  margin-bottom: 5px;
  color: red;
}
