.video_block_news > div > iframe {
  width: 100%;
  height: 100%;
}

.right_arrow {
  content: "\01F862";
}

body[dir="rtl"] .right_arrow {
  transform: rotate(180deg);
  display: inline-block;
}

.see_all_video_wrap .yout_video_icn {
  position: absolute;
  width: 40px;
  margin: 0 auto;
  padding: 8px 0;
  border-radius: 7px;
  background: red;
  top: calc(50% - 15px);
  left: 0;
  right: 0;
}

body[dir="rtl"] .see_all_video_wrap .yout_video_icn {
  left: 0;
  right: 0;
  margin: 0 auto;
}
