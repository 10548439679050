.tabs_tab > div {
  display: inline-block;
  padding: 1em;
  width: 160px;
  text-align: center;
  background: #768699;
  color: #fff;
  cursor: pointer;
}

.top_losers_tables .tabs_tab > div {
  min-width: 160px;
  width: auto;
}

.tabs_tab .active:hover,
.tabs_tab .active {
  background: #fff;
  color: #000;
}

.tab_container {
  background: #fff;
}

.top_trades.tabs_tab > div {
  width: calc(100% / 7);
  text-align: center;
  padding: 13px 0;
  line-height: 1;
  border-right: 1px solid #f1f1f1;
  box-sizing: border-box;
}

body[dir="rtl"] .top_trades.tabs_tab > div {
  border-left: 1px solid #f1f1f1;
  border-right: none;
}

.top_trades.tabs_tab > div:last-child {
  border-right: none;
}

body[dir="rtl"] .top_trades.tabs_tab > div:last-child {
  border-left: none;
}

.top_trades {
  display: flex;
  background: #768699;
  flex-flow: row wrap;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  border-bottom: none;
}

.trades_info {
  padding: 30px 30px 20px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  border-top: none;
  background: #fff;
}

.trades_block {
  display: flex;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
}

.trades_part {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

body[dir="rtl"] .trades_part {
  margin-left: 40px;
  margin-right: 0;
}

.trades_part .startment {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.text_color {
  color: #68ac1d;
}

.trades_part span:last-child {
  font-size: 14px;
}

.trades_block .trades_part:last-child {
  margin: 0;
}

.deal_cont {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.deal_left,
.deal_right {
  width: calc(50% - 10px);
  padding: 0 20px;
  background: #f2f2f2;
}

.deal_left > div,
.deal_right > div {
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
}

.deal_ttl {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
}

.deal_block {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.deal_txt {
  font-weight: 400;
  color: #757575;
}

.deal_num {
  font-weight: 500;
  color: #000;
}

body[dir="rtl"] .deal_num {
  float: left;
}

.deal_footer span:first-child {
  margin-right: 10px;
}

body[dir="rtl"] .deal_footer span:first-child {
  margin-left: 10px;
  margin-right: 0;
}

.deal_footer span,
.deal_footer a {
  font-size: 14px;
}

.deal_left > div:last-child,
.deal_right > div:last-child {
  border-bottom: none;
}

thead td.table_head:last-child:before {
  content: "";
  width: 12px;
  height: 12px;
  background: #ff4f65;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: -1px;
}

body[dir="rtl"] thead td.table_head:last-child:before {
  content: "";
  margin-left: 10px;
  margin-right: 0;
}

body[dir="rtl"] .tab_wrap .tabs_tab {
  display: flex;
  justify-content: flex-start;
}
.tab_wrap .tab_container thead td.table_head {
  font-size: 12px;
  color: #000;
  font-weight: 500;
}
.tab_wrap .tab_container {
  padding: 20px;
}

.tab_wrap .tab_container table tbody tr:last-child {
  border-bottom: none;
}

.tab_wrap tbody tr:last-child .table_head {
  padding-bottom: 0;
}

.tab_wrap td.table_head:nth-child(2) {
  width: 30%;
}

.tab_wrap .tab_container .table_head {
  padding: 11px 5px;
  width: 17.5%;
  text-align: left;
  font-weight: normal;
}

body[dir="rtl"] .tab_wrap .tab_container .table_head {
  text-align: right;
}

.tab_container {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.tab_wrap .tabs_tab .active {
  font-weight: normal;
  /*box-shadow: -2px -3px 8px -2px rgba(0, 0, 0, .1);*/
}

.top_losers_tables {
  width: 50%;
}
.select_mobile {
  display: none;
}
@media (max-width: 1366px) {
  .top_losers_tables {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .tab_container > div {
    overflow: auto;
  }
  .tab_container table {
    min-width: 910px;
  }
}

@media (max-width: 600px) {
  .top_trades.tabs_tab > div {
    display: none;
  }
  .top_trades.tabs_tab {
    padding: 15px;
    background: #fff;
  }
  .select_cont.select_mobile select {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    padding: 5px 10px;
    cursor: pointer;
    text-align: left;
    color: #404040;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: 0;
    background: #fff;
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .top_trades tabs_tab {
    position: relative;
  }
  .select_mobile {
    display: block;
    width: 100%;
    position: relative;
  }
  .select_mobile:after {
    position: absolute;
    top: 6px;
    right: 12px;
    display: inline-block;
    width: 8px;
    height: 8px;
    content: "";
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    pointer-events: none;
    border-top: 1px solid #4a4a4a;
    border-right: 1px solid #4a4a4a;
  }
  .tab_container {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  }
  .trades_info .trades_block {
    flex-wrap: wrap;
  }
  .trades_part {
    margin: 0 10px 15px 0;
    width: calc(50% - 10px);
  }

  body[dir="rtl"] .trades_part {
    margin: 0 0px 15px 10px;
  }

  .trades_part:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  .trades_info {
    padding: 20px;
  }

  .deal_cont {
    flex-direction: column;
  }

  .deal_left {
    margin-bottom: 15px;
  }

  .deal_left,
  .deal_right {
    width: 100%;
  }
  .top_losers_tables .tabs_tab > div {
    width: 50%;
    min-width: auto;
  }
}

body[dir="rtl"] .deal_footer {
  flex-direction: inherit;
  display: flex;
}

@media (max-width: 992px) {
  .trades_part .startment {
    font-size: 14px;
  }
}

.calculatorBtn_block {
  text-align: center;
  padding: 0 0 40px 0;
}

.separationWall {
  height: 40px;
  background-color: #f1f1f1;
  margin: 30px -25px -41px;
  padding: 10px 0px;
  z-index: 1000;
}

.custom-style {
  background: #f1f1f1;
  margin: 0px -25px 0px -20px;
}

body[dir="rtl"] .custom-style {
  margin: 0px -20px 0px -25px;
}

.custom-style-trigger {
  padding: 20px;
  margin: 0px -20px -20px -20px;
}

.MarketMoversSelects .col {
  display: flex;
  align-items: flex-end;
}

.MarketMoversSelects .DatePicker {
  margin: 0;
}

.container.MarketMoversSelects {
  padding: 0;
}
