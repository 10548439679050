.swipe {
  display: none;
}

.block_val table tr th:nth-child(1),
.block_val table tr td:nth-child(1) {
  width: 10%;
  text-align: left;
}

body[dir="rtl"] .block_val table tr th:nth-child(1),
body[dir="rtl"] .block_val table tr td:nth-child(1) {
  text-align: right;
}

.block_val table tr th:nth-child(2),
.block_val table tr td:nth-child(2) {
  width: 40%;
  text-align: right;
}

body[dir="rtl"] .block_val table tr th:nth-child(2),
body[dir="rtl"] .block_val table tr td:nth-child(2) {
  text-align: left;
}

.block_val table tr th:nth-child(3),
.block_val table tr td:nth-child(3) {
  width: 50%;
  text-align: right;
}

body[dir="rtl"] .block_val table tr th:nth-child(3),
body[dir="rtl"] .block_val table tr td:nth-child(3) {
  text-align: left;
}

@media (max-width: 550px) {
  .chart_table_wrap .swipe {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }

  .chart_table {
    overflow: auto;
  }

  .chart_table table {
    min-width: 550px;
  }
}
