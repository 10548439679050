.live_quotes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.quote_block {
    font-family: Montserrat, sans-serif;
    display: flex;
    flex-direction: column;
    width: calc(20% - 12px);
    margin-right: 15px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, .1);
}

body[dir='rtl'] .quote_block {
    margin-right: 0;
    margin-left: 15px;
}

.live_quotes .quote_block:last-child {
    margin-right: 0;
}

body[dir='rtl'] .live_quotes .quote_block:last-child {
    margin-left: 0;
}

.select_cont {
    position: relative;
}

.select_cont > select {
    font-weight: 500;
    display: flex;
    width: 100%;
    height: 30px;
    margin-bottom: 5px;
    padding: 5px 10px;
    cursor: pointer;
    text-align: left;
    color: #404040;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    outline: 0;
    background: #fff;
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select_cont:after {
    position: absolute;
    top: 6px;
    right: 12px;
    display: inline-block;
    width: 8px;
    height: 8px;
    content: '';
    transform: rotate(45deg);
    pointer-events: none;
    border-right: 1px solid #4a4a4a;
    border-bottom: 1px solid #4a4a4a;
}

body[dir='rtl'] .select_cont:after {
    right: 0;
    left: 12px;
}

.select_cont:focus {
    outline: none;
}

.big_num {
    font-size: 26px;
    font-weight: normal;
    margin-bottom: 5px;
    padding: 0 10px 0 0;
    color: black;
}

body[dir='rtl'] .big_num {
    padding: 0 0 0 10px;
    text-align: justify;
}

.green {
    color: #68ac1d;
}

.red_num {
    color: #ff4f65 !important;
}

.black {
    color: black;
}

.chart_body > .child {
    width: 50%;
    text-align: justify;
}

.chart_body > .child:nth-child(2) {
    height: 95px;
}

.quote_block > div:last-child {
    font-size: 12px;
    font-weight: 500;
    height: 75px;
    padding: 0 0 0 10px;
    color: #68ac1d;
}

body[dir='rtl'] .quote_block > div:last-child {
    padding: 0 10px 0 0;
}

@media (max-width: 992px) {
    .live_quotes {
        justify-content: center;
    }

    .quote_block {
        width: calc(32% - 4px);
        margin-bottom: 15px;
    }

    .quote_block:nth-of-type(3) {
        margin-right: 0;
    }

    body[dir='rtl'] .quote_block:nth-of-type(3) {
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .quote_block:nth-of-type(3) {
        margin-right: 15px;
    }

    body[dir='rtl'] .quote_block:nth-of-type(3) {
        margin-right: 0;
        margin-left: 15px;
    }

    .quote_block {
        width: calc(50% - 8px);
        margin-bottom: 15px;
    }

    .quote_block:nth-child(odd) {
        margin-right: 0;
    }

    body[dir='rtl'] .quote_block:nth-child(odd) {
        margin-left: 0;
    }

    .live_quotes .quote_block:last-child {
        margin-bottom: 0;
    }
}

@media (max-width: 450px) {
    .quote_block {
        width: 100%;
        margin-bottom: 15px;
    }

    .quote_block:nth-child(even) {
        margin-right: 0;
    }

    body[dir='rtl'] .quote_block:nth-child(even) {
        margin-left: 0;
    }
}