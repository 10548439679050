.top_broker > div {
  display: flex;
  margin-bottom: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d9e3f0;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.left_part_broker {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

body[dir="rtl"] .left_part_broker {
  margin-left: 10px;
  margin-right: 0;
}

.expel_class .rating {
  margin: 0 15px 0 15px;
}

.rating {
  margin-right: 10px;
  margin-left: 20px;
}

body[dir="rtl"] .rating {
  margin-left: 10px;
  margin-right: 20px;
}

.rating span {
  line-height: 1;
}

.right_arrow {
  content: "\01F862";
}

.top_brokers_logo {
  width: 80px;
  height: 40px;
  text-align: center;
}

.left_part_broker + a {
  border-radius: 2px;
}

@media (max-width: 360px) {
  .top_broker > div {
    justify-content: center;
  }
}
