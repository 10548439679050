.video_cont_block {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
}
.video_cont_blocks > div {
  display: flex;
  flex-direction: column;
  max-width: 322px;
  width: 100%;
  position: relative;
  margin: auto;
}
.video_cont_block:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.video_cont_block:last-child .video_les {
  margin-bottom: 20px;
}
.vid_cont_block_txt {
  width: calc(100% - 342px);
}
.video_less_title {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 15px;
  color: #000;
  text-align: left;
}

body[dir="rtl"] .video_less_title {
  text-align: right;
}

.vdl_footer_bl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vid_cont_block_txt > p {
  margin-bottom: 20px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: #4a4a4a;
  text-align: left;
}
.ifcm-pdf {
  margin-right: 15px;
}
.pdf_text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: #000000;
  text-align: left;
  display: inline-block;
}

/*MarketOverViewList*/
.new_cont_block {
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.6;
}

.tech_analysis_img img {
  height: 190px;
  width: 100%;
  max-width: 375px;
  float: left;
  padding: 0 20px 20px 0;
}

body[dir="rtl"] .new_cont_block {
  text-align: right;
}

@media (max-width: 576px) {
  .tech_analysis_img img{
    max-width: initial;
    padding-right: 0;
  }
}

.new_cont_block + .vdl_footer_bl {
  margin-bottom: 15px;
}
/*End MarketOverViewList*/

@media (max-width: 1160px) {
  .main_with_left + div .education_section {
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  .video_less_wrap .video_cont_block {
    flex-direction: column;
    padding-bottom: 20px;
  }
  .video_less_wrap .vid_cont_block_txt {
    width: 100%;
  }
}

/*expert style*/
.expert-block {
  display: flex;
}

.new_cont_block.expert-block img {
  padding: 0;
  width: auto;
}


.education-video {
  width: 50%;
  flex-direction: column;
  padding: 40px 20px 20px;
  margin-bottom: 0;
}

body[dir="rtl"] div.education-video:nth-child(odd){
  border-left: 1px solid #d8d8d8;
  border-right: none;
}

div.education-video:nth-child(odd){
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
}

div.education-video:nth-child(even){
  border-bottom: 1px solid #d8d8d8;
}

div.education-video:nth-last-of-type(-n+2){
  border-bottom: 0;
}

.education-video .video_less_title,
.education-video .vid_cont_block_txt > p,
body[dir="rtl"] .education-video .video_less_title,
body[dir="rtl"] .education-video .vid_cont_block_txt > p{
  text-align: center;
}

@media (max-width: 576px){
  .education-video {
    width: 100%;
  }

  div.education-video:nth-child(odd) {
    border-right: none;
  }
  body[dir="rtl"] div.education-video:nth-child(odd) {
    border-left: none;
  }
}
