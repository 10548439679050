* {
    box-sizing: border-box;
}

::-webkit-scrollbar-track {
    border-radius: 0;
    background: #fff;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    border-radius: 0;
    background: #f1f1f1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
    height: 80px;
    border-radius: 10px;
    background: #ccc;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

body {
    font-family: Roboto, sans-serif !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    position: initial;
    overflow: auto;
    margin: 0;
    text-align: justify;
    color: #4a4a4a !important;
}

h1 > span {
    text-transform: uppercase;
}

.mb-30 {
    margin-bottom: 30px !important;
}
.mb-60 {
    margin-bottom: 60px !important;
}
.font-30{
    font-size: 30px;
}

.h25 {
    height: 25px;
}

ul {
    margin: 0 !important;
    padding: 0;
    list-style: outside none;
}

.ul-tick li {
    line-height: 26px;
    position: relative;
    padding-left: 30px;
    text-align: left;
}

body[dir='rtl'] .ul-tick li {
    padding-right: 30px;
    padding-left: 0;
    text-align: right;
}

.ul-tick li:before {
    font-family: 'gorexgrad';
    font-size: 13px;
    line-height: 25px;
    position: absolute;
    left: 0;
    content: '\E807';
    color: #7bab2e;
}

body[dir='rtl'] .ul-tick li:before {
    right: 0;
    left: auto;
}

.container {
    position: relative;
    max-width: 1440px !important;
}

.content .container:last-child {
    margin-bottom: 0;
}
strong{
    font-weight: bold !important;
}
.content {
    padding-top: 30px;
    padding-bottom: 50px;
    background: #f1f1f1;
}

.arrow {
    position: relative;
    top: -1px;
    margin-left: 4px;
    padding: 3px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    vertical-align: 2px;
    border: solid #1d1d1d;
    border-width: 0 1px 1px 0;
}

body[dir='rtl'] .arrow {
    margin-right: 4px;
    margin-left: 0;
}

p {
    text-align: left;
}

body[dir='rtl'] p {
    text-align: right;
}

table {
    width: 100%;
    margin-bottom: 17px;
    text-align: justify;
}

table th,
table tr {
    box-sizing: border-box;
    border-bottom: 1px solid #d8d8d8;
}

table th {
    font-size: 12px;
    font-weight: normal;
    padding: 10px 7px;
    color: #717171;
}

table td {
    padding: 10px 7px;
}

body[dir='rtl'] table td {
    text-align: right;
    direction: ltr;
}

a {
    transition: .4s;
    text-decoration: none;
    color: #4a90e2;
}

.main_with_left h1 {
    padding-bottom: 0;
}

h2,
h5,
.main_with_right h1,
.main_with_left h1,
.widthcalendarTab h1 {
    font-family: Montserrat, Sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    width: 100%;
    margin: 0 0 15px !important;
    text-align: justify;
    color: #000;
}

.main_half {
    display: inline-block;
}

.amcharts-chart-div a {
    display: none !important;
}

.widthcalendar {
    width: 50%;
}

.widthcalendarTab {
    width: 100%;
}

.main_full_width {
    display: inline-block;
    width: 100%;
}

.main_with_left.commeasurable {
    display: flex;
    flex-direction: column;
}

.commeasurable > div {
    height: 100%;
}

.main_with_left {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-right: 20px;
}

body[dir='rtl'] .main_with_left {
    margin-right: 0;
    margin-left: 20px;
}

.main_with_right {
    display: inline-block;
    width: 70%;
    margin-left: 20px;
}

body[dir='rtl'] .main_with_right {
    margin-right: 20px;
    margin-left: 0;
}

@media (max-width: 950px) {
    .main_with_left {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    body[dir='rtl'] .main_with_left {
        width: 100%;
        margin-left: 0;
    }
}

.video_less_wrap.main_with_left + .right_box_block > .right_box {
    margin-bottom: 20px;
}

.right_box_block,
.left_box_block {
    display: flex;
    flex-direction: column;
    width: calc(30% - 20px);
}

.right_box > div,
.left_box > div {
    padding: 20px;
    text-align: left;
    background: #fff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, .1);
}

body[dir='rtl'] .right_box > div,
body[dir='rtl'] .left_box > div {
    text-align: right;
}

.date_block {
    font-family: Montserrat, Sans-serif;
    font-size: 12px;
    font-weight: normal;
    display: flex;
    width: 100%;
    max-width: 90px;
    padding: 5px 10px;
    text-align: center;
    color: #000;
    background-color: #ffcc36;
    justify-content: center;
    align-items: center;
}

.btn {
    font-weight: 300 !important;
    transition: .4s !important;
    color: #fff !important;
    border-radius: 0 !important;
}

.button_green {
    border: 1px solid #94c93d !important;
    background: #94c93d !important;
}

.button_green:hover {
    background: #84b433 !important;
}

.button_grayish_blue {
    font-weight: normal !important;
    border: 1px solid #768699 !important;
    background: #768699 !important;
}

.button_grayish_blue:hover {
    color: #fff;
    border: 1px solid #54657a !important;
    background: #54657a !important;
}

.btnActive {
    color: #fff !important;
    border: 1px solid #54657a !important;
    background: #54657a !important;
}

.btn_lg {
    font-size: 16px !important;
    line-height: 50px !important;
    min-width: 240px !important;
    height: 50px;
    padding: 0 30px !important;
}

.btn_md {
    font-size: 14px !important;
    line-height: 40px !important;
    min-width: 150px;
    height: 40px;
    padding: 0 20px !important;
}

.btn_sm {
    font-size: 14px !important;
    line-height: 30px !important;
    height: 30px;
    padding: 0 20px !important;
}

.btn_100 {
    font-size: 14px !important;
    line-height: 40px !important;
    min-width: 100px !important;
    height: 40px;
    padding: 0 20px !important;
}

/*------------------------------ FOREX BROKERS RATING ------------------------------*/
.forex_rating_wrap .bg_wrap {
    margin-bottom: 10px;
}

.forex_rating_wrap .bg_wrap:last-child {
    margin-bottom: 0;
}

/*------------------------------ END FOREX BROKERS RATING ------------------------------*/

/*Review added successfully*/

#register {
    position: relative;
}

.ok_wrap {
    display: inline-block;
    width: 100%;
    padding-top: 50%;
}

.txt_1 {
    font-size: 20px;
    font-weight: 500;
    position: relative;
    display: flex;
    height: 40px;
    margin-bottom: 15px;
    animation: txt 3s 1;
    background: #fff;
    justify-content: center;
    align-items: center;
}

@keyframes txt {
    0% {
        margin-bottom: -40px;
    }
    30% {
        margin-bottom: -40px;
        letter-spacing: 1px;
    }
    85% {
        margin-bottom: -40px;
        letter-spacing: 0;
    }
}

/*END Review added successfully*/

body[dir='rtl'] .icon-right-small:before {
    transform: rotate(180deg);
}

@media screen and (max-width: 1365px) {
    ::-webkit-scrollbar-track {
        border-radius: 0;
        background: #fff;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar {
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 0;
        background: #f1f1f1;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar-thumb {
        height: 8px;
        border-radius: 10px;
        background: #ccc;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }
}

.arrow_up {
    position: relative;
    top: 2px;
    margin-left: 10px;
    padding: 3px;
    -webkit-transition: .4s;
    transition: .4s;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    vertical-align: 2px;
    border: solid #1d1d1d;
    border-width: 0 1px 1px 0;
}

.arrow_down {
    position: relative;
    top: -2px;
    margin-left: 10px;
    padding: 3px;
    -webkit-transition: .4s;
    transition: .4s;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    vertical-align: 2px;
    border: solid #1d1d1d;
    border-width: 0 1px 1px 0;
}

body[dir='rtl'] .arrow_up,
body[dir='rtl'] .arrow_down {
    margin-right: 10px;
    margin-left: 0;
}

.pagination {
    display: inline-block;
    margin: 20px 0 0;
    padding-left: 0;
    border-radius: 4px;
}

.pagination > li {
    display: inline;
}

.pagination > li.disabled {
    display: none;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    z-index: 3;
    cursor: default;
    color: #fff;
    border-color: #68ac1d;
    background-color: #68ac1d;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
    cursor: not-allowed;
    color: #777;
    border-color: #ddd;
    background-color: #fff;
}

.pagination > li > a,
.pagination > li > span {
    line-height: 1.42857143;
    position: relative;
    display: flex;
    float: left;
    width: 32px;
    height: 32px;
    margin-right: 5px;
    padding: 6px;
    text-decoration: none;
    color: #765e7e;
    border: 1px solid #ddd;
    background-color: #fff;
    justify-content: center;
    align-items: center;
}

/*---------- Analytics table ----------*/
.tech_table table {
    min-width: 260px;
    margin-bottom: 0;
}

.tech_table table tr:nth-child(even) > td {
    background: #f1f1f1;
}

.tech_table table tr > td {
    text-align: center;
    border-right: 1px solid #ccc;
}

body[dir='rtl'] .tech_table table tr > td {
    text-align: center;
    border-right: none;
    border-left: 1px solid #ccc;
}

.tech_table table tr > td:last-child {
    border-right: none;
}

body[dir='rtl'] .tech_table table tr > td:last-child {
    border-left: none;
}

.tech_table table tr {
    border: none;
}

.tech_table table tr:first-child {
    border-top: 1px solid #ccc;
}

.tech_table table tr:last-child {
    border-bottom: 1px solid #ccc;
}

.tech_table table > tr > td {
    font-weight: 300;
    padding: 6px 12px;
}

.tech_table tr td {
    line-height: 1.71;
    color: #000;
}

.tech_table tr td:first-child {
    font-weight: 500;
}

/*---------- End Analytics table ----------*/

/*---------------------------------------- market_overwiev ----------------------------------------*/
.m_overwiev table {
    border: none !important;
}

.m_overwiev table tr:first-child {
    border-top: 1px solid #ccc;
}
/*---------------------------------------- END m_overwiev ----------------------------------------*/

@media (max-width: 1160px) {
    .main_with_left.market_overview_videos,
    .main_with_left.video_less_wrap,
    .main_with_left.video_tutorial_cont,
    .main_with_left.cfd_books_cont,
    .main_with_left.tech_analysis,
    .main_with_left.forex_company,
    .main_with_left.about_us_wrap,
    .main_with_left.innov_block,
    .main_with_left.trading_plt,
    .main_with_left.comments,
    .main_with_left.articles,
    .main_with_left.see_also,
    .main_with_left.curr_p_wrap,
    .main_with_left.market_movers {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    body[dir='rtl'] .main_with_left.market_overview_videos,
    body[dir='rtl'] .main_with_left.video_tutorial_cont,
    body[dir='rtl'] .main_with_left.video_less_wrap,
    body[dir='rtl'] .main_with_left.cfd_books_cont,
    body[dir='rtl'] .main_with_left.tech_analysis,
    body[dir='rtl'] .main_with_left.forex_company,
    body[dir='rtl'] .main_with_left.about_us_wrap,
    body[dir='rtl'] .main_with_left.innov_block,
    body[dir='rtl'] .main_with_left.trading_plt,
    body[dir='rtl'] .main_with_left.articles,
    body[dir='rtl'] .main_with_left.comments,
    body[dir='rtl'] .main_with_left.see_also,
    body[dir='rtl'] .main_with_left.curr_p_wrap,
    body[dir='rtl'] .main_with_left.market_movers {
        margin-left: 0;
    }

    .main_with_left.market_overview_videos + div,
    .main_with_left.video_tutorial_cont + div,
    .video_less_wrap.main_with_left + div,
    .main_with_left.cfd_books_cont + div,
    .main_with_left.forex_company + div,
    .main_with_left.about_us_wrap + div,
    .main_with_left.comments + div,
    .main_with_left.tech_analysis + div,
    .trading_plt.main_with_left + div,
    .main_with_left.innov_block + div,
    .main_with_left.articles + div,
    .main_with_left.see_also + div,
    .main_with_left.curr_p_wrap + div,
    .main_with_left.market_movers + div {
        width: 100%;
    }
}

input,
textarea {
    outline: none;
}

.marketOverviewRss {
    display: flex;
    flex-direction: column;
}

.new_cont_block img {
    width: 100%;
    max-width: 450px;
    height: auto;
    padding: 20px 0 20px;
}

.not-found {
    width: 100%;
    text-align: center;
    background-color: white;
}

.not-found h1 {
    font-size: 50px;
    margin: 0;
    text-align: center;
    border-bottom: none;
}

.not-found div:first-child {
    margin: 20px;
    padding: 100px 0;
    border: 1px solid #9b9b9b47;
}

.video_less_title + .new_cont_block p:first-of-type img {
    padding-top: 0;
}

/*get items from market-watch rss and use in market-overview */
.feedflare {
    display: none;
}

.feedflare + img {
    display: none;
}

/*rss from ifcm tech-analis*/
.emailSummary + table {
    display: none;
}

h2.emailSummary {
    display: none;
}