.accordion-item__line {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    padding: 8px 20px 8px 10px;
    cursor: pointer;
    background-color: #f1f1f1;
    justify-content: space-between;
    align-items: center;
}

.accordion-item__inner {
    position: relative;
    z-index: 1;
    overflow: hidden;
    max-height: 0;
    transition-duration: .5s;
    transition-property: max-height;
    text-transform: cubic-bezier(.95, .05, .795, .035);
}

.accordion-item--opened .accordion-item__inner {
    max-height: 120rem;
    transition-timing-function: cubic-bezier(.895, .03, .685, .22);
    transition-duration: .5s;
    transition-property: max-height;
}

.accordion-item__title {
    display: flex;
    align-items: center;
}

.accordion-item__title img {
    margin-right: 10px;
    border-radius: 50%;
}

body[dir='rtl'] .accordion-item__title img {
    margin-right: 0;
    margin-left: 10px;
}

.author-block {
    font-family: Montserrat, Sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-align: justify;
    color: #000;
}

.accordion-item__title .author {
    display: block;
    color: #4a4a4a;
}

.accordion-item__content {
    padding: 10px 0 20px;
    transition: max-height .2s ease-in-out;
}

.accordion-item--opened .accordion-item__content {
    transition-delay: .2s;
    transition-timing-function: ease-in-out;
    transition-duration: .2s;
    transition-property: opacity, transform;
    transform: translateY(0);
    opacity: 1;
}

.accordion-item__paragraph {
    font-family: Montserrat, Sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    margin: 0;
    color: #000;
}

.acc-arrow {
    position: relative;
    top: -2px;
    width: 12px;
    height: 12px;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: solid #4a4a4a;
    border-width: 0 2px 2px 0;
}

.accordion-item--opened .acc-arrow {
    top: 2px;
    -webkit-transform: rotate(45deg);
    transform: rotate(225deg);
}

.question-text-link {
    font-family: Montserrat, Sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    display: block;
    margin-bottom: 5px;
    text-align: justify;
    text-decoration: underline;
    color: #2173a3;
}

.question-text-link:last-of-type {
    margin-bottom: 0;
}

.questions_wrap h2 {
    font-size: 18px;
}

.questions_wrap h3 {
    font-size: 18px;
    font-weight: normal;
}
