.books_blk_title {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 15px;
  color: #000;
}
.trading_books p {
  line-height: 1.71;
  margin-bottom: 20px;
}
.books_blk_footer {
  display: flex;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d9e3f0;
  justify-content: space-between;
  align-items: center;
}
.trading_books:last-child .books_blk_footer {
  margin: 0;
  padding-bottom: 10px;
  border: none;
}
.books_left_part {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.regarding_block .author_regarding {
  margin-right: 30px;
  padding-right: 30px;
  border-right: 1px solid #d9e3f0;
}

.book_regarding {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  margin-right: 30px;
  padding-right: 30px;
  border-right: 1px solid #d9e3f0;
}
.book_regarding:last-child {
  border: none;
  padding: 0;
}

.book_regarding div:last-child {
  color: #000;
}
