.childlist .childlist_wrapper {
  width: 100%;
}
.news_title.component_title {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin-bottom: 10px;
}
.news_title div h2 {
  display: flex;
  width: 100%;
  margin-bottom: 0;
  padding: 0px 0 10px 0;
  font-weight: 400;
  align-items: center;
}
.advant_cont_block {
  position: relative;
  width: 100%;
  padding-bottom: 35px;
}
.component_text2 img {
  height: 300px;
  width: auto;
}
.button_line {
  display: inline-block;
  width: 100%;
  height: 1px;
  content: "";
  background: #ccc;
}

.button_line .getchild_wrap {
  bottom: 25px;
  display: flex;
}
.getchild_wrap {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 0 0 10px;
}
.childlist .news_title div a,
.childlist .news_title div span {
  color: #000;
}
