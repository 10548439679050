.videoButton {
  position: absolute;
  width: 72px;
  height: 72px;
  background-image: url("./../../assets/images/banner-play.svg");
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.6;
  cursor: pointer;
  border-radius: 50px;
}
.videoButton:hover {
  position: absolute;
  width: 72px;
  height: 72px;
  background-image: url("./../../assets/images/banner-play.svg");
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  cursor: pointer;
  border-radius: 50px;
}
