.diiiv {
  width: 20px;
  height: 20px;
  margin: 5px;
  /*background-color: #2cff1b;*/
  border: 1px solid #ccc;
}
.active {
  /*background-color: #2cff1b;*/
}

.diiiv.active {
  border: 1px solid #2cff1b;
  background-color: #2cff1b;
}

.second {
  background: #fff;
  padding: 1px 6px;
  border: 1px solid #ccc;
}

.diiiv.active:before {
  content: "";
  border: solid #fff;
  display: inline-block;
  float: left;
  width: 3px;
  height: 10px;
  margin-top: 1px;
  margin-left: 6px;
  padding: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  border-width: 0 3px 3px 0;
}

.first {
  display: flex;
  align-items: center;
  line-height: 1;
  height: 36px;
  padding: 1px 6px;
  border: 1px solid #ccc;
  outline: none;
  box-shadow: none;
  background: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

.first:before {
  content: "";
  width: 8px;
  height: 8px;
  border-top: 1px solid #999;
  border-right: 1px solid #999;
  transform: rotate(135deg);
  right: 10px;
  top: 12px;
  position: absolute;
  pointer-events: none;
  display: inline-block;
}
.secondItem {
  display: flex;
  height: 30px;
  width: 200px;
  align-items: center;
}
.secondItem:first-child {
  display: flex;
  height: 30px;
  width: 200px;
}
.secondItem:last-child {
  display: flex;
  height: 30px;
  width: 200px;
}
.text {
  margin: 3px;
}
