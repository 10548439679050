.rating_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.acc_type_info {
  width: 100%;
  float: left;
  margin-top: 30px;
}
.st_left {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

[data-forhalf="★"] {
  margin-right: 5px;
}

.st_left span:last-child {
  margin-right: 0;
}

.st_left .rating_stars {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: normal;
  margin-left: 30px;
  padding-top: 5px;
}

.st_left .rating_stars span {
  font-weight: 500;
}

.reviews_block {
  text-align: right;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
}

.rating_info div {
  height: 34px;
  border-bottom: 1px dashed #ccc;
  position: relative;
}

.rating_info div > span {
  position: absolute;
  background: #fff;
  top: 8px;
  padding: 0 3px;
}

.rating_info div > span:first-child {
  left: 0;
  font-size: 16px;
  font-weight: 300;
  color: #9b9b9b;
  line-height: 40px;
}

.rating_info div > span:last-child {
  right: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  color: #000;
  line-height: 40px;
}
.rating_info tbody tr td:first-child {
  width: 32%;
  left: 0;
  font-size: 15px;
  font-weight: 300;
  color: #9b9b9b;
  line-height: 40px;
}

.rating_info tbody{
  display: block;
}
.rating_info tbody tr  {
  display: flex;
  justify-content: space-between;
  border: none;
}
.rating_info tbody tr td span {
  font-weight: 500;
}
.rating_info tbody tr td {
  padding: 10px 0px;
  margin: auto 0;
}
.rating_info tbody tr td:last-child {
  right: 0;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  color: #000;
  line-height: 40px;
  text-align: center;
  width: 70%;
}
.rating_info {
  width: calc(100% - 310px);
  margin-right: 30px;
  float: left;
}

.offers {
  max-width: 280px;
  width: 100%;
  background-color: #f1f1f1;
  padding: 14px 19px;
  float: left;
  margin-bottom: 20px;
}

.offers .ul-tick li:before {
  line-height: 33px;
}

.offers .ul-tick li.there_is_0:before {
  font-family: "gorexgrad";
  font-size: 14px;
  line-height: 34px;
  position: absolute;
  left: 0;
  content: "\e80b";
  color: #999;
}

.offers .ul-tick li.there_is_0 {
  color: #999;
}

.offers li {
  font-size: 16px;
  font-weight: normal;
  line-height: 34px;
  color: #000;
}

.acc_type_info .rating_info > span {
  margin-right: 12px;
  display: inline-block;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  line-height: 2;
  border-bottom: 1px dashed #ccc;
  color: #9b9b9b;
  cursor: pointer;
}

.acc_type_info .rating_info > span.active {
  color: #68ac1d;
  font-weight: bold;
  border-bottom: 1px dashed #68ac1d;
}

.acc_type_info .rating_info {
  margin-bottom: 36px;
}

.acc_type_info .rating_info > span.show_options {
  display: inline-block;
  width: 100%;
  border: none;
  color: #000;
  margin-top: 10px;
}

.rating_info h2 {
  margin-bottom: 20px;
}
.show_options {
  margin-bottom: 20px;
  display: block;
  padding-top: 10px;
}

.show_options_cont span {
  display: inline-block;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  padding: 7px 15px;
  background-color: #f1f1f1;
  margin: 5px;
}
.arrow_to_show {
  border: solid #4a90e2;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin: 0 0 3px 10px;
  transform: rotate(45deg);
  transition: 0.4s;
}

body[dir="rtl"] .arrow_to_show {
  margin: 0 10px 3px 0;
}

body[dir="rtl"] .arrow_to_show.u_turn {
  margin: 0px 10px 0px 0;
}

.extra_block {
  color: #4a90e2;
  cursor: pointer;
}

.u_turn {
  transform: rotate(-135deg);
  margin-bottom: 0;
}

.arrow_to_show:hover {
  cursor: pointer;
}

.broker_logo {
  max-width: 160px;
  height: 80px;
}

.main_with_left h1 > strong {
  color: #9b9b9b;
}

.text-overflow-60 {
  display: inline-block;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rating_wrap .rating_info div span.text-wrap {
  display: inline-block;
  max-width: 60%;
  line-height: 25px;
  top: 15px;
  position: relative;
  float: right;
}

.broker_txt_block {
  margin-bottom: 40px;
}

@media (max-width: 840px) {
  .rating_info {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }
  .offers {
    max-width: initial;
  }
}

@media (max-width: 550px) {
  .rating_header {
    flex-direction: column;
    align-items: center;
  }
  .rating_info tbody tr td span{
    font-size: 14px;
  }
  .rating_info tbody tr td:first-child {
    width: 50%;
  }
  .rating_info tbody tr td:last-child {
    width: 50%;
  }
  .st_left .rating_stars {
    font-size: 20px;
    padding-top: 8px;
  }

  .rating_info div {
    border: none;
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .rating_info div > span {
    position: initial;
    padding: 0;
  }
  .rating_info div > span:last-child,
  .rating_info div > span:first-child {
    line-height: 1.6;
    text-align: center;
  }
  .rating_info > div {
    margin-bottom: 20px;
    align-items: center;
  }

  .show_options {
    text-align: center;
    padding-top: 0;
    margin-top: -20px;
  }

  .show_options_cont {
    display: flex;
    flex-flow: row wrap;
  }
  .show_options_cont span {
    width: calc(50% - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .show_options_cont span:last-child,
  .show_options_cont span:first-child {
    margin-left: 5px;
  }

  .acc_type_info .rating_info > span {
    margin: 0 12px 20px;
    width: calc(50% - 24px);
    text-align: center;
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .fb .reviews_cont > div {
    flex-direction: column;
    align-items: flex-start;
  }

  .fb .left_part_reviews .rating {
    margin: 0;
  }
}
