.glossary_text div {
  margin-bottom: 20px;
  line-height: 25px;
}

.left_letter {
  width: 40px;
  height: 1200px;
  border-right: solid 2px #7cc212;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.left_letter div {
  cursor: pointer;
  width: 41px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right_letter_a {
  width: 40px;
  height: 40px;
  background-color: #7cc212;
  color: #fff;
  border-radius: 100%;
  padding: 11px;
  text-align: center;
  box-sizing: border-box;
}

.glossary_right_text {
  width: 100%;
  margin-left: 30px;
}
.glossary_second_text {
  display: flex;
  justify-content: space-between;
}
.right_text h3 {
  margin-top: 15px;
  font-weight: 700;
  color: #4a90e2;
}

.active_green {
  width: 41px;
  height: 40px;
  border-right: solid 2px white;
  border-top: solid 2px #7cc212;
  border-left: solid 2px #7cc212;
  border-bottom: solid 2px #7cc212;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9px 0 9px 0;
}
.demo_btn {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
