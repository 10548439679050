.MarketMoversSelects {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  display: flex;
}

.MarketMoversSelects select {
  width: 100%;
  line-height: 1;
  height: 36px;
  padding: 1px 6px;
  border: 1px solid #ccc;
  outline: none;
  box-shadow: none;
  background: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.MarketMoversSelects .select {
  position: relative;
}

.custom-row .col > div {
  width: 100%;
}
.MarketMoversSelects .select:after {
  content: "";
  width: 8px;
  height: 8px;
  border-top: 1px solid #999;
  border-right: 1px solid #999;
  transform: rotate(135deg);
  right: 10px;
  top: 12px;
  position: absolute;
  pointer-events: none;
  display: inline-block;
}

.MarketMoversSelects option {
  width: 200px;
  height: 40px;
}

.DatePicker {
  width: 180px;
  margin: 27px 10px;
  text-align: center;
  line-height: 1;
  height: 36px;
  padding: 1px 6px;
  border: 1px solid #ccc;
  outline: none;
  box-shadow: none;
  background: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  align-items: center;
}

.checkbox input {
  width: 50px;
  height: 50px;
  border: 1px solid black;
}

.popover-container {
  width: 200px;
}

.popover-container button {
}

.popover {
  height: 200px;
  border: 1px solid gray;
}

.toggleDiv {
  height: 30px;
  border: 1px solid;
}

.popoverItem {
  padding: 5px;
}

.popoverItem div {
  padding: 5px;
  width: 10px;
  height: 10px;
}

.popoverItem div .active {
  background-color: #7cc212;
}

.popoverItem button {
  height: 20px;
  width: 20px;
  border: 1px solid gray;
}

@media (min-width: 1200px) {
  .container,
  .custm_container {
    max-width: 1440px;
  }

  .custm_container,
  .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
