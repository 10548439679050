.reviews_cont {
  padding-left: 20px;
  border-left: 2px solid #dedede;
  margin-bottom: 30px;
}

body[dir="rtl"] .reviews_cont {
  padding-right: 20px;
  padding-left: 0;
  border-right: 2px solid #dedede;
  border-left: none;
}

.reviews_cont:last-child {
  margin-bottom: 0;
}

.left_part_reviews {
  display: flex;
  margin-bottom: 15px;
  align-items: flex-end;
}

.top_reviews_img {
  margin-right: 40px;
}

body[dir="rtl"] .top_reviews_img {
  margin-left: 40px;
  margin-right: 0;
}

.left_part_reviews .rating {
  margin-right: 10px;
}

body[dir="rtl"] .left_part_reviews .rating {
  margin-left: 10px;
  margin-right: 0;
}

.rating_num {
  font-weight: normal;
}

.reviews_txt {
  font-weight: normal;
  margin-bottom: 5px;
}

.reviews_author,
.reviews_date {
  font-weight: normal;
  font-style: italic;
  color: #9b9b9b;
}

/*FOREX BROKER*/

.fb .slash {
  color: #9b9b9b;
  font-weight: normal;
}

.slash {
  margin: 0 5px;
}

.fb .reviews_cont {
  padding: 5px 15px 5px 20px;
}

.fb .left_part_reviews {
  margin-bottom: 0;
}

.fb .left_part_reviews .rating {
  margin-left: 30px;
}

body[dir="rtl"] .fb .left_part_reviews .rating {
  margin-right: 30px;
  margin-left: 0;
}

.fb .reviews_cont > div {
  display: flex;
  align-items: center;
  line-height: 1.6;
}

.reviews_cont {
  border-left: 2px solid #f1f1f1;
}

body[dir="rtl"] .reviews_cont {
  border-right: 2px solid #f1f1f1;
  border-left: none;
}

.reviews_cont.yello_green,
.fb .reviews_cont.yello_green {
  border-left: 2px solid #7ed321;
}

body[dir="rtl"] .reviews_cont.yello_green,
body[dir="rtl"] .fb .reviews_cont.yello_green {
  border-right: 2px solid #7ed321;
  border-left: none;
}

.reviews_cont.wewak,
.fb reviews_cont.wewak {
  border-left: 2px solid #ff8695;
}

body[dir="rtl"] .reviews_cont.wewak,
body[dir="rtl"] .fb reviews_cont.wewak {
  border-right: 2px solid #ff8695;
  border-left: none;
}

.reviews_cont.hawkes_blue,
.fb .reviews_cont.hawkes_blue {
  border-left: 2px solid #d9e3f0;
}

body[dir="rtl"] .reviews_cont.hawkes_blue,
body[dir="rtl"] .fb .reviews_cont.hawkes_blue {
  border-right: 2px solid #d9e3f0;
  border-left: none;
}

/*END FOREX BROKER*/
