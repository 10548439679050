.edu_sec_blk {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.edu_sec_blk .left_part {
  display: flex;
  align-items: center;
  text-align: left;
}

body[dir="rtl"] .edu_sec_blk .left_part {
  text-align: right;
}

.education_section {
  padding-top: 40px;
}

.edu_sec {
  margin-bottom: 10px;
}

.edu_sec:last-child {
  margin-bottom: 0;
}

.yout_video_icn {
  position: relative;
  width: 40px;
  padding: 8px 0;
  border-radius: 7px;
  background: red;
}

.yout_video_icn div {
  width: 0;
  height: 0;
  margin: 0 10px 0px 16px;
  border-width: 7px 0 7px 10px;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
}

.left_part > div {
  font-size: 36px;
  line-height: 0;
  margin-left: -8px;
  margin-right: 20px;
  color: #768699;
}

body[dir="rtl"] .left_part > div {
  margin-right: -8px;
  margin-left: 20px;
}

.edu_sec .left_part {
  font-size: 16px;
  font-weight: normal;
  color: #4a4a4a;
  margin-right: 10px;
}

body[dir="rtl"] .edu_sec .left_part {
  margin-left: 10px;
  margin-right: 0;
}

.left_part > div.yout_video_icn {
  margin-left: 0;
  margin-right: 29px;
}

body[dir="rtl"] .left_part > div.yout_video_icn {
  margin-right: 0;
  margin-left: 29px;
}
