.main_with_left h1 {
  font-size: 20px;
  margin: 0 0 15px;
  color: #000;
  font-family: Montserrat,sans-serif;
  border-bottom: none;
}

.expel_class {
  margin-bottom: 20px;
}

.expel_class:last-child {
  margin-bottom: 0;
}
