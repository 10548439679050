.video_toturial_block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.video_tutorial {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid lightgrey;
}

.video_tutorial:last-child {
  border-bottom: none;
}

.video_les {
  display: flex;
  height: 187px;
  margin-bottom: 30px;
  cursor: pointer;
  background: url(../../../../assets/images/toturial/what_is_forex.jpg) center
    no-repeat;
  align-items: center;
  justify-content: center;
  background-size: contain;
  position: relative;
}

.video_les .yout_video_icn,
body[dir="rtl"] .video_les .yout_video_icn {
  margin: 0;
}

.yout_video_icn:hover {
  opacity: 0.5;
  cursor: pointer;
}

.video_toturial {
  display: flex;
  flex-flow: row wrap;
}
.video_toturial > div {
  border-bottom: 1px solid #d8d8d8;
}
.video_toturial > div:last-child {
  border-bottom: none;
}
.video_toturial > .leftSide:nth-last-child(-n + 2) {
  border-bottom: none;
}
.leftSide,
.rightSide {
  box-sizing: border-box;
  width: 50%;
  min-width: 320px;
  padding: 40px 30px 30px;
  text-align: center;
}

.leftSide {
  border-right: 1px solid #d8d8d8;
}

.video_tutorial {
  border-bottom: 1px solid #d8d8d8;
}

.video_tutorial:last-child {
  border-bottom: none;
}

.video_tutorial p {
  font-weight: normal;
}

@media (max-width: 710px) {
  .leftSide,
  .rightSide {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    min-width: auto;
  }

  .leftSide {
    border-right: none;
    border-bottom: 1px solid #d8d8d8;
  }
  .video_tutorial:last-child > div {
    border-bottom: none;
  }
  .video_tutorial .video_les {
    display: flex;
    margin: 0 auto 20px;
    max-width: 322px;
    width: 100%;
    height: 190px;
  }
  .video_tutorial p {
    text-align: center;
  }
}

@media (max-width: 350px) {
  .leftSide,
  .rightSide {
    padding: 20px 0 30px;
  }
  .video_tutorial:first-child > div {
    padding-top: 0;
  }
}
