.page_title,
.mb0 {
  margin-bottom: 0;
}
.page_content {
  width: 100%;
}
.button_line {
  display: inline-block;
  width: 100%;
  height: 1px;
  content: "";
  background: #ccc;
}
.ifc_markets_new_wrapper_data {
  padding: 0 0 10px 0;
}
.button_line .getchild_wrap {
  bottom: 25px;
  display: flex;
}
.getchild_wrap {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 0 0 10px;
}
.news_title div h2 {
  display: flex;
  width: 100%;
  margin-bottom: 0;
  align-items: center;
}

.news_title div h2 {
  display: flex;
  width: 100%;
  margin-bottom: 0;
  padding: 0px 0 0px 0;
  font-weight: 400;
  align-items: center;
}

.button_line .getchild_wrap {
  bottom: 25px;
  display: flex;
  background: #f1f1f1;
}
.getchild_wrap {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 0 0 10px;
  background: #fff;
}
.getchild_wrap a {
  transition: 0.4s;
  text-decoration: none;
  color: #7bab2e;
  border: 1px solid;
  padding: 5px 10px;
}
.getchild_wrap a:hover {
  transition: 0.4s;
  text-decoration: none;
  color: white;
  background-color: #7bab2e;
  border: 1px solid;
  padding: 5px 10px;
}

.filterTitle {
  margin: 30px 0;
}
.market_mover_container .market_desc {
  font-size: medium;
}
.ifc_markets_new_wrapper_data {
  padding: 0 0 10px 0;
}
