.bg_wrap .expert_block {
  width: 100%;
  font-family: Montserrat;
  font-weight: normal;
  color: #000000;
}
.main_with_left .loader {
  font-family: Roboto;
  text-transform: none;
}
.bitcoin_image {
  background-image: url("../../images/bitcoin.png");
  width: 320px;
  height: 250px;
  margin-left: 20px;
}
.txt_introduction {
  width: calc(100% - 320px);
  margin-top: 30px;
}
.txt_introduction p {
  margin-top: 25px;
  font-style: italic;
  line-height: 1.71;
}
.txt_container_introduction {
  display: flex;
}

.txt_container_oil_trend {
  padding-top: 20px;
}
.txt_container_oil_trend p {
  margin-top: 20px;
}
.txt_container_oil_trend h3 {
  font-size: 18px;
  margin-bottom: 20px;
}
.oil_markets_table {
  width: 100%;
  text-align: center;
  font-weight: normal;
  line-height: 1.71;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 15px;
}
.oil_markets_table thead th:first-child,
.oil_markets_table tbody td:first-child {
  text-align: left;
}

.oil_markets_table .swipe {
  display: none;
}

.articles_cont p {
  margin-bottom: 10px;
}

.articles_cont p:last-child,
.articles_cont .articles_block:last-child {
  margin-bottom: 0;
}

.articles_cont .articles_block {
  margin-bottom: 20px;
}

.articles_block img {
  width: 100%;
  object-fit: scale-down;
}

.txt_link {
  color: #4a4a4a;
}

.txt_link:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 800px) {
  .oil_markets_table .swipe {
    display: block;
  }

  .oil_markets_table .swipe + div {
    overflow: auto;
  }

  .oil_markets_table table {
    min-width: 800px;
  }
}
@media (max-width: 650px) {
  .bitcoin_image {
    display: none;
  }

  .txt_introduction {
    width: 100%;
    margin-top: 0;
  }
  .articles_block .swipe {
    display: block;
    text-align: center;
    margin-bottom: 15px;
  }

  .articles_block .brokers_table {
    overflow: auto;
  }

  .articles_block.tech_table table {
    min-width: 900px;
  }
}
