.footer .top_menu_wrapper,
.footer .top_menu_wrapper ul,
.footer .top_menu_wrapper ul li,
.footer .top_menu_wrapper ul li a,
.footer .top_menu_wrapper #menu-button {
  justify-content: center;
}

.fa-facebook-f, .fa-instagram, .fa-twitter {
  color:#ffffff;
  font-size: 25px;
  margin: 0 15px
}
.social_block{
  background-color: #1a1a1a;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  margin: auto;
}
.f_text {
  background: #1a1a1a;
  padding: 5px 0 30px 0;
  color: #999;
}

.footer .container {
  margin-bottom: 0;
}

.footer .menu,
.footer > div:last-child {
  background: #252525;
}

.footer > div:last-child p {
  padding: 12px;
  text-align: center;
}

.footer p {
  margin: 0;
  font-weight: normal;
  color: #929292;
}

@media (max-width: 992px) {
  .footer .menu {
    display: none;
  }
}
.content .input-form-email[data-v-7e14c6f2]{
  width: 100% !important;
}
