.sort_by ul li {
  font-weight: normal;
  display: flex;
  margin-right: 20px;
  list-style: none;
  align-items: center;
}

body[dir="rtl"] .sort_by ul li {
  margin-left: 20px;
  margin-right: 0;
}

.sort_by ul li:last-child {
  margin-right: 0;
}

body[dir="rtl"] .sort_by ul li:last-child {
  margin-left: 0;
}

.sort_by ul {
  display: flex;
  margin: 0;
  padding: 15px;
}

.sort_by {
  border: solid 1px #e8e8e8;
  background-color: #f1f1f1;
}

.sort_by ul li:first-child {
  color: #000;
}

.sort_by ul li:last-child > div:first-child {
  color: #9b9b9b;
  cursor: auto;
  margin-right: 5px;
}

body[dir="rtl"] .sort_by ul li:last-child > div:first-child {
  margin-left: 5px;
  margin-right: 0;
}

.sort_by li {
  cursor: pointer;
}
